import {
  CreateCodeArguments,
  createCode
} from '../../Homepage/CreateFlowcodeForm/CreateFlowcodeFormDesktop/CreateFlowcodeFormDesktop.hooks'
import { DEFAULT_ERROR, getGqlError, getGqlErrorMessage } from '../../../utils/errors'
import {
  DOWNLOAD_FLOWCODE_SUCCESS_MESSAGE,
  FLOWCODE_THEME_OPTIONS
} from '../../../constants/flowcode'
import { GqlErrorCodes } from '../../../constants/gql-errors'
import { LANDING_PAGE_FORM_INITIAL_VALUES } from '../../../constants/landing-page'
import {
  LandingPageDestination,
  LandingPageDestinationType,
  LandingPageFormValuesType
} from '@dtx-company/inter-app/src/types/flowcode'
import { constructScanDestinationLink } from '../../utils/constructScanDestinationLink'
import { downloadFlowcode as downloadFlowcodeUtil } from '@app/code/src/utils/downloadFlowcode'
import { getFlowcodeId } from '../../../utils/flowcode'
import { initialCreateFlowcodeState } from '../../../redux/slices/createFlowcodeSlice'
import { logger } from '@dtx-company/logger'
import {
  selectAutogeneratedOptions,
  selectBody,
  selectCountryCode,
  selectFile,
  selectLogo,
  selectScanDestination,
  selectScanDestinationType,
  selectSubject
} from '../../../machines/locg/locgSelectors'
import {
  sendErrorNotification,
  sendSuccessNotification
} from '@dtx-company/inter-app/src/utils/notifications'
import { updateDownloadCodeData } from '@dtx-company/inter-app/src/redux/slices/homePageSlice'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCallback, useMemo } from 'react'
import { useCreateFlowcodeStateActions } from '../../Homepage/CreateFlowcodeForm/CreateFlowcodeForm.hooks'
import { useDispatch } from 'react-redux'
import { useFlowcodeOptions } from '../hooks/useFlowcodeOptions'
import { useGlobalServices } from '@app/common/src/hooks/useGlobalServices'
import { useScreenSizeBreakpoints } from '@app/common/src/hooks/useScreenSizeBreakpoints'
import { useSelector } from '@xstate/react'

type LoggedOutGeneratorCreateCode = (
  onSuccess?: (batchId: string) => void,
  onError?: () => void
) => Promise<void>

export function useBuildLoggedOutGeneratorCreateCodePayload(): CreateCodeArguments {
  const { token } = useAuthState()
  const { locgService } = useGlobalServices()
  const selectedDestinationType = useSelector(locgService, selectScanDestinationType)
  const selectedDestination = useSelector(locgService, selectScanDestination)
  const body = useSelector(locgService, selectBody)
  const subject = useSelector(locgService, selectSubject)
  const selectedCountryCode = useSelector(locgService, selectCountryCode)
  const selectedFile = useSelector(locgService, selectFile)
  const selectedLogo = useSelector(locgService, selectLogo)
  const selectedAutogeneratedOptions = useSelector(locgService, selectAutogeneratedOptions)

  const options = useFlowcodeOptions()

  const destinationFormValues: LandingPageFormValuesType = useMemo(() => {
    return {
      ...LANDING_PAGE_FORM_INITIAL_VALUES,
      link: constructScanDestinationLink(selectedDestination, selectedDestinationType),
      ...(selectedDestinationType === LandingPageDestination.SMS && {
        sms: {
          message: body ?? '',
          phoneNumber: selectedCountryCode
            ? `+${selectedCountryCode}-${selectedDestination}`
            : selectedDestination
        }
      }),
      ...(selectedDestinationType === LandingPageDestination.EMAIL && {
        email: {
          email: selectedDestination,
          subject: subject ?? '',
          body: body ?? ''
        }
      }),
      file: selectedFile || null
    }
  }, [
    body,
    selectedDestination,
    selectedDestinationType,
    selectedFile,
    selectedCountryCode,
    subject
  ])
  return {
    selectedDestination: { id: selectedDestinationType } as LandingPageDestinationType,
    options,
    template: null,
    theme: FLOWCODE_THEME_OPTIONS[0],
    destinationFormValues,
    token,
    centerImageObjectURL: typeof selectedLogo === 'string' ? selectedLogo : '',
    ...(selectedLogo && {
      centerLogoImageFile: typeof selectedLogo != 'string' ? selectedLogo : null
    }),
    isAutoDesign: Boolean(selectedAutogeneratedOptions)
  }
}

export function useLoggedOutGeneratorCreateAndDownloadCode(): LoggedOutGeneratorCreateCode {
  const { setLoadingCreateFlowcode } = useCreateFlowcodeStateActions()
  const dispatch = useDispatch()
  const createCodeArgs = useBuildLoggedOutGeneratorCreateCodePayload()
  const { isMobileOrTablet } = useScreenSizeBreakpoints()

  const loggedOutGeneratorCreateCode = useCallback<LoggedOutGeneratorCreateCode>(
    async (onSuccess, onError) => {
      try {
        setLoadingCreateFlowcode(true)

        const { flowcodeOptions, flowcodeBatch } = await createCode({
          ...createCodeArgs
        })
        const flowcodeId = getFlowcodeId(flowcodeBatch.shortUrl)
        // this is needed for the case is user exists and has reached the code limit.
        // The data is read from redux in PaymentStep component

        dispatch(
          updateDownloadCodeData({
            codeOptions: JSON.stringify(flowcodeOptions),
            codeBatchId: flowcodeId
          })
        )

        await downloadFlowcodeUtil({
          targetFileType: initialCreateFlowcodeState.downloadFileType,
          flowcodeOptions,
          flowcodeId: flowcodeId // it is used only for logging
        })

        onSuccess
          ? onSuccess(flowcodeBatch.batchId)
          : () => {
              if (isMobileOrTablet) return
              sendSuccessNotification(DOWNLOAD_FLOWCODE_SUCCESS_MESSAGE)
            }
      } catch (e) {
        logger.logError(e, { technicalArea: 'authentication' })
        const { code } = getGqlError(e, false)
        if (e.code === GqlErrorCodes.CODE_LIMIT_ERROR || code === GqlErrorCodes.CODE_LIMIT_ERROR) {
          onError?.()
        } else {
          // getGqlError was displaying a snackbar, moving that to here because
          // we don't want the snackbar in the case of a limit error that also opens
          // the upsell modal
          const message = getGqlErrorMessage(e, DEFAULT_ERROR.message)
          sendErrorNotification(message)
        }
      } finally {
        setLoadingCreateFlowcode(false)
      }
    },
    [setLoadingCreateFlowcode, createCodeArgs, dispatch, isMobileOrTablet]
  )

  return loggedOutGeneratorCreateCode
}
