import { FcGeneratorOptions, FcShape } from '@dtx-company/flowcode-generator-types/src'

export type Options =
  | 'containerShape'
  | 'containerEmptySpaceSize'
  | 'gridModuleRandomSizes'
  | 'dataGridPercentageSize'
  | 'containerOuterPatternRandomSizes'
  | 'containerImageOverlayPrivacy'
  | 'containerImageUrl'
  | 'containerImageUrlFillSvg'

// if privacy text is removed from the container image update flowcodeOptions generation
export const getSquareShapeOptions = (
  id: string,
  isSinclairOrgUser?: boolean
): Pick<FcGeneratorOptions, Options> => {
  let randomSizes = '99'
  let containerImageUrl = isSinclairOrgUser
    ? 'https://cdn.flowcode.com/prodassets/sinclair_container_shaggy.svg'
    : 'https://cdn.flowcode.com/prodassets/container_shaggy.svg'

  switch (id.toLowerCase()) {
    case 'diamond':
      randomSizes = '105'
      containerImageUrl = isSinclairOrgUser
        ? 'https://cdn.flowcode.com/prodassets/sinclair_container_diamond.svg'
        : 'https://cdn.flowcode.com/prodassets/container_diamond.svg'
      break
    case 'heart':
      randomSizes = '95'
      containerImageUrl = isSinclairOrgUser
        ? 'https://cdn.flowcode.com/prodassets/sinclair_container_heart.svg'
        : 'https://cdn.flowcode.com/prodassets/container_heart.svg'
      break
    case 'star':
      randomSizes = '115'
      containerImageUrl = isSinclairOrgUser
        ? 'https://cdn.flowcode.com/prodassets/sinclair_container_star.svg'
        : 'https://cdn.flowcode.com/prodassets/container_star.svg'
      break
    case 'triangle':
      randomSizes = '110'
      containerImageUrl = isSinclairOrgUser
        ? 'https://cdn.flowcode.com/prodassets/sinclair_container_triangle.svg'
        : 'https://cdn.flowcode.com/prodassets/container_triangle.svg'
      break
    case 'square':
      randomSizes = '76'
      containerImageUrl = isSinclairOrgUser
        ? 'https://cdn.flowcode.com/prodassets/sinclair_container_square.svg'
        : 'https://cdn.flowcode.com/prodassets/container_square.svg'
      break
    case 'circle':
      randomSizes = '85'
      containerImageUrl = isSinclairOrgUser
        ? 'https://cdn.flowcode.com/prodassets/sinclair_container_circle.svg'
        : 'https://cdn.flowcode.com/prodassets/container_circle.svg'
      break
  }

  return {
    containerShape: FcShape.SQUARE,
    containerEmptySpaceSize: 12,
    gridModuleRandomSizes: randomSizes,
    dataGridPercentageSize: 90,
    containerOuterPatternRandomSizes: randomSizes,
    containerImageOverlayPrivacy: false,
    containerImageUrl: containerImageUrl,
    containerImageUrlFillSvg: true
  }
}
