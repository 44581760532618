import {
  DEFAULT_FLOWCODE_OPTIONS,
  DEFAULT_LOCG_CIRCLE_WITH_FRAME_CONTAINER_TEXT_FONTSIZE,
  DEFAULT_LOCG_CIRCLE_WITH_FRAME_CONTAINER_TEXT_OFFSET,
  DEFAULT_LOCG_CONTAINER_TEXT_FONTSIZE,
  DEFAULT_LOCG_CONTAINER_TEXT_OFFSET
} from '../../../../LoggedOutCodeGenerator.constants'
import {
  FcColorBlack,
  FcGeneratorOptions,
  FcPosition,
  FcPositionProperty,
  FcShape,
  FcText
} from '@dtx-company/flowcode-generator-browser/src'
import { SINCLIAR_ORG_CODE_PRIVACY_TEXT } from '../../../../../../containers/create-flowcode-fullpage/constants'

/**
 * Create container text. Changes based on frame and shape
 */

export const createFrameContainerText = (
  shape: FcShape,
  showFrame: boolean,
  fontColor = FcColorBlack,
  isSinclairOrgUser?: boolean
): FcText[] => {
  let containerTextFontSize = DEFAULT_FLOWCODE_OPTIONS.containerText[0].fontSize
  let containerTextOffset = DEFAULT_FLOWCODE_OPTIONS.containerText[0].position.offsetPercentage
  const containerText = isSinclairOrgUser ? SINCLIAR_ORG_CODE_PRIVACY_TEXT : 'PRIVACY.FLOWCODE.COM'
  if (showFrame) {
    if (shape === FcShape.SQUARE) {
      containerTextFontSize = DEFAULT_LOCG_CONTAINER_TEXT_FONTSIZE
      containerTextOffset = DEFAULT_LOCG_CONTAINER_TEXT_OFFSET
    } else {
      containerTextFontSize = DEFAULT_LOCG_CIRCLE_WITH_FRAME_CONTAINER_TEXT_FONTSIZE
      containerTextOffset = DEFAULT_LOCG_CIRCLE_WITH_FRAME_CONTAINER_TEXT_OFFSET
    }
  } else {
    if (shape === FcShape.CIRCLE) {
      containerTextFontSize = DEFAULT_LOCG_CONTAINER_TEXT_FONTSIZE
      containerTextOffset = DEFAULT_LOCG_CONTAINER_TEXT_OFFSET
    }
  }

  return [
    new FcText(
      containerText,
      'inter_medium',
      containerTextFontSize,
      fontColor,
      new FcPosition(FcPositionProperty.BOTTOM, containerTextOffset)
    )
  ]
}

export const createContainerText = (
  config: FcGeneratorOptions,
  fontColor = FcColorBlack,
  shape: FcShape,
  isSinclairOrgUser?: boolean
): FcText[] => {
  // square codes have container image
  if (shape === FcShape.SQUARE) return []
  if (config.containerText?.length) {
    return [
      {
        ...config?.containerText?.[0],
        ...(isSinclairOrgUser && {
          text: SINCLIAR_ORG_CODE_PRIVACY_TEXT
        }),
        fontColor,
        // reposition text if the code previously had a frame
        ...(config?.containerText?.[0]?.position?.offsetPercentage ===
          DEFAULT_LOCG_CIRCLE_WITH_FRAME_CONTAINER_TEXT_OFFSET && {
          position: {
            ...config.containerText[0].position,
            offsetPercentage: DEFAULT_LOCG_CONTAINER_TEXT_OFFSET
          }
        })
      }
    ]
  } else {
    // same as the default in createFrameContainerText when showFrame is false
    return [
      new FcText(
        isSinclairOrgUser ? SINCLIAR_ORG_CODE_PRIVACY_TEXT : 'PRIVACY.FLOWCODE.COM',
        'inter_medium',
        DEFAULT_LOCG_CONTAINER_TEXT_FONTSIZE,
        fontColor,
        new FcPosition(FcPositionProperty.BOTTOM, DEFAULT_LOCG_CONTAINER_TEXT_OFFSET)
      )
    ]
  }
}
